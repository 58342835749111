import { NETWORKS } from "src/constants/NetworkNames";
import { CURRENCIES } from "src/constants/Currencies";
import { truncate } from "src/helpers/string.helper";
import { LinkableExternal as Link } from "src/components/Linkable";

export function networkName(id) {
  const network = NETWORKS.find((item) => item.id === id);
  return network ? network.name : '"Unknown Network"' + id;
}

export function contractLinkURL(id, address) {
  const network = NETWORKS.find((item) => item.id === id);
  return network ? network.baseURL + "address/" + address : "";
}

export function tokenLinkURL(id, address, tokenID) {
  const network = NETWORKS.find((item) => item.id === id);
  return network ? network.baseURL + `token/${address}` + (tokenID ? `?a=${tokenID}` : '') : "";
}

export function transactionLinkURL(id, tx) {
  const network = NETWORKS.find((item) => item.id === id);
  return network ? network.baseURL + `tx/${tx}` : "";
}

export function shortAccount(account) {
  return account ? truncate(account, 11) : "";
}

export function isValidAddress(address) {
  if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
    // Check if it has the basic requirements of an address
    return false;
  }

  return true;
}

export function getAvailableCurrencies(networkID) {
  const network = NETWORKS.find((item) => item.id === networkID);
  if (network && network.currencies) return network.currencies;

  return [];
}

export function getERC20ContractAddress(currency, networkID) {
  const currencyInfo = CURRENCIES.find((item) => item.symbol === currency);
  if (currencyInfo) {
    return currencyInfo.contract[networkID] ?? null;
  }

  return null;
}

export function getValidOrEmptyAddress(address) {
  if (isValidAddress(address)) return address;
  return '0x0000000000000000000000000000000000000000';
}