import { useRef, useState } from "react";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";
import Tabs from "src/components/Tabs";
import TextField from "src/components/FormikTextField";
import ImagePicker from "src/components/ImagePicker";
import { useCategoryState } from "src/state";

const ProjectForm = ({ defaultData, onSave }) => {
  const { promised: isCategoryLoading, categories } = useCategoryState();

  const formik = useFormik({
    initialValues: {
      category: defaultData ? defaultData.category : "",
      project_name: defaultData ? defaultData.project_name : "",
      project_tagline: defaultData ? defaultData.project_tagline : "",
      project_country: defaultData ? defaultData.project_country : "",
      project_address: defaultData ? defaultData.project_address : "",
      owner_name: defaultData ? defaultData.owner_name : "",
      sales_return: defaultData ? defaultData.sales_return : "",
      capital_request: defaultData ? defaultData.capital_request : "",
      offering_size: defaultData ? defaultData.offering_size : "",
      target_irr: defaultData ? defaultData.target_irr : "",
      sales_return_on_capital: defaultData
        ? defaultData.sales_return_on_capital
        : "",
      average_cash_yield: defaultData ? defaultData.average_cash_yield : "",
      project_desc: defaultData ? defaultData.project_desc : "",
      project_timeline: defaultData ? defaultData.project_timeline : "",
      social_linkedin: defaultData ? defaultData.social_linkedin : "",
      usp_1: defaultData ? defaultData.usp_1 : "",
      usp_2: defaultData ? defaultData.usp_2 : "",
      usp_3: defaultData ? defaultData.usp_3 : "",
      usp_4: defaultData ? defaultData.usp_4 : "",
      usp_5: defaultData ? defaultData.usp_5 : "",
      usp_6: defaultData ? defaultData.usp_6 : "",
      registration_cert_updated:
        defaultData && defaultData.registration_cert_updated
          ? defaultData.registration_cert_updated
          : "",
      licenses: defaultData ? defaultData.licenses : "",
      tax_license_no: defaultData ? defaultData.tax_license_no : "",
      distribution_partners: defaultData
        ? defaultData.distribution_partners
        : "",
      prove_assets_registration: defaultData
        ? defaultData.prove_assets_registration
        : "",
      use_funds: defaultData ? defaultData.use_funds : "",
      project_details: defaultData ? defaultData.project_details : "",
      sales_projections: defaultData ? defaultData.sales_projections : "",
      project_map_desc: defaultData ? defaultData.project_map_desc : "",
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        //upload Images/files if attached
        if (selectedFilesBuffers.current) {
          for (const key in selectedFilesBuffers.current) {
            let fileHash = null;
            if (selectedFilesBuffers.current[key]) {
              fileHash = await uploadFileToIPFS(
                selectedFilesBuffers.current[key]
              );
            }
            values[key] = hash_to_ipfs_link(fileHash);
          }
        }

        formikHelpers.setSubmitting(false);

        // return back the data to parent page
        onSave && onSave(values);
      } catch (error) {
        formikHelpers.setSubmitting(false);
        console.error(error);
        return;
      }
    },
    validationSchema: Yup.object().shape({
      project_name: Yup.string().max(100).required("Project Name is required"),
      category: Yup.string().required("Please select a category"),
    }),
  });

  const selectedFilesBuffers = useRef({});

  const handleFileSelect = (filename, file) => {
    if (file) {
      readBlobToBuffer(file).then(
        (fileBuffer) => (selectedFilesBuffers.current[filename] = fileBuffer)
      );
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Tabs>
          <Box label={`Project Info`}>
            <Box mb={6}>
              <Typography variant="h3" mb={2}>
                Project Category
              </Typography>
              {!isCategoryLoading && (
                <Select
                  fullWidth
                  name="category"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.category}
                >
                  {categories.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Typography variant="h3" mb={2}>
              Specs
            </Typography>
            <Grid container spacing={4} rowSpacing={1}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Project Name"
                  margin="normal"
                  name="project_name"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Project Tagline"
                  margin="normal"
                  name="project_tagline"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Project Country"
                  margin="normal"
                  name="project_country"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Project Address"
                  margin="normal"
                  name="project_address"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  label="Project Owner Name"
                  margin="normal"
                  name="owner_name"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  label="Project Sales Return"
                  margin="normal"
                  name="sales_return"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  label="Project Capital Request"
                  margin="normal"
                  name="capital_request"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Typography variant="h3" mt={6} mb={2}>
              Project Summary
            </Typography>
            <Grid container spacing={4} rowSpacing={1}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Offering Size"
                  margin="normal"
                  name="offering_size"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Target IRR (Sales of Produce)"
                  margin="normal"
                  name="target_irr"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Sales Return on Capital"
                  margin="normal"
                  name="sales_return_on_capital"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Average Cash Yield"
                  margin="normal"
                  name="average_cash_yield"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Project Desc"
                  margin="normal"
                  name="project_desc"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Project Timeline"
                  margin="normal"
                  name="project_timeline"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Typography variant="h3" mt={6} mb={2}>
              Social Links
            </Typography>
            <Grid container spacing={4} rowSpacing={1}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="LinkedIn"
                  margin="normal"
                  name="social_linkedin"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          <Box label="Project USP">
            <Grid container spacing={4} rowSpacing={1}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="USP 1"
                  margin="normal"
                  name="usp_1"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="USP 2"
                  margin="normal"
                  name="usp_2"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="USP 3"
                  margin="normal"
                  name="usp_3"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="USP 4"
                  margin="normal"
                  name="usp_4"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="USP 5"
                  margin="normal"
                  name="usp_5"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="USP 6"
                  margin="normal"
                  name="usp_6"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          <Box label="Project Authenticity">
            <Grid container spacing={4} rowSpacing={1}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Biz registration cert updated"
                  margin="normal"
                  name="registration_cert_updated"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Licenses"
                  margin="normal"
                  name="licenses"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Tax License No."
                  margin="normal"
                  name="tax_license_no"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Distribution Partners"
                  margin="normal"
                  name="distribution_partners"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label="Prove of Assets Registration"
                  margin="normal"
                  name="prove_assets_registration"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          <Box label="Financial Snapshot">
            <Grid container spacing={4} rowSpacing={1}>
              {/* <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Use of Funds"
                  margin="normal"
                  name="use_funds"
                  type="text"
                  variant="outlined"
                />
              </Grid> */}
              <Grid item md={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={8}
                  label="Project Details"
                  margin="normal"
                  name="project_details"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Sales Projections"
                  margin="normal"
                  name="sales_projections"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={8}
                  label="Project Map Desc"
                  margin="normal"
                  name="project_map_desc"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          <Box label={`Project Gallery`}>
            <Grid container spacing={4}>
              <Grid item md={3}>
                <Typography variant="h3" mb={2}>
                  Project Image
                </Typography>
                <ImagePicker
                  withDragDrop
                  singleColumn
                  name="project_image"
                  onFileSelect={handleFileSelect}
                  value={defaultData?.project_image}
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="h3" mb={2}>
                  Timeline Image
                </Typography>
                <ImagePicker
                  withDragDrop
                  singleColumn
                  name="timeline_image"
                  onFileSelect={handleFileSelect}
                  value={defaultData?.timeline_image}
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="h3" mb={2}>
                  Use of Funds
                </Typography>
                <ImagePicker
                  withDragDrop
                  singleColumn
                  name="use_funds"
                  onFileSelect={handleFileSelect}
                  value={defaultData?.use_funds}
                />
              </Grid>
              <Grid item md={3}>
                <Typography variant="h3" mb={2}>
                  Project Map
                </Typography>
                <ImagePicker
                  withDragDrop
                  singleColumn
                  name="project_map"
                  onFileSelect={handleFileSelect}
                  value={defaultData?.project_map}
                />
              </Grid>
            </Grid>
            <Box mb={6}>
              <Typography variant="h3" mt={6} mb={2}>
                Other Images
              </Typography>
              <Grid container spacing={4}>
                <Grid item md={3}>
                  <ImagePicker
                    withDragDrop
                    singleColumn
                    name="project_gallery_1"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_gallery_1}
                  />
                </Grid>
                <Grid item md={3}>
                  <ImagePicker
                    withDragDrop
                    singleColumn
                    name="project_gallery_2"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_gallery_2}
                  />
                </Grid>
                <Grid item md={3}>
                  <ImagePicker
                    withDragDrop
                    singleColumn
                    name="project_gallery_3"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_gallery_3}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box label={`Project Docs`}>
            <Box mb={6}>
              <Grid container spacing={4}>
                <Grid item md={2}>
                  <Typography variant="body1" mb={2}>
                    Doc 1
                  </Typography>
                  <ImagePicker
                    name="project_docs_1"
                    accept="*"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_docs_1}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body1" mb={2}>
                    Doc 2
                  </Typography>
                  <ImagePicker
                    name="project_docs_2"
                    accept="*"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_docs_2}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body1" mb={2}>
                    Doc 3
                  </Typography>
                  <ImagePicker
                    name="project_docs_3"
                    accept="*"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_docs_3}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body1" mb={2}>
                    Doc 4
                  </Typography>
                  <ImagePicker
                    name="project_docs_4"
                    accept="*"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_docs_4}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body1" mb={2}>
                    Doc 5
                  </Typography>
                  <ImagePicker
                    name="project_docs_5"
                    accept="*"
                    onFileSelect={handleFileSelect}
                    value={defaultData?.project_docs_5}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Tabs>
        <Box sx={{ px: 3, mb: 2 }}>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              "Save Details"
            )}
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default ProjectForm;
