import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Box,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import TextField from "src/components/FormikTextField";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { useGlobalState, useUserState } from "src/state";
import {
  alreadyClaimed,
  claim as requestClaim,
} from "src/plugins/EthereumClaimDemo";

const Claim = () => {
  const blockchainInfo = useContext(BlockchainContext);
  const { profile } = useUserState();
  const { setRedirectToAfterConnect } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [claimPending, setClaimPending] = useState(true);

  const connect = () => {
    //save the current URL to state
    //to redirect user back to this page
    setRedirectToAfterConnect(location.pathname);

    if (!blockchainInfo.account) {
      navigate("/connect-wallet#buy");
      return;
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: 5,
    },
    onSubmit: async (values, actions) => {
      if (blockchainInfo.account) {
        setLoading(true);
        requestClaim(values.amount)
          .then(() => {
            setClaimed(values.amount);
          })
          .catch((e) => {})
          .finally(() => setLoading(false));
      } else connect();
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required("You must provide a value to claim"),
    }),
  });

  useEffect(() => {
    if (blockchainInfo && blockchainInfo.account) {
      setLoading(true);
      alreadyClaimed()
        .then((claim) => {
          if (claim && claim.length === 2) {
            setClaimed(claim[0]);
            setClaimPending(parseInt(claim[1]) ? false : true);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [blockchainInfo]);

  return (
    <Box className="container" py={10}>
      <h1>Claim your Funds</h1>
      <p>Choose the amount you would like to claim and submit</p>
      {loading ? (
        <CircularProgress />
      ) : claimed ? (
        <Box sx={{ mt: 3 }}>
          <h3>You have claimed for WETH {claimed}</h3>
          <h4>
            {claimPending
              ? "Your claim is pending"
              : " Your claim is fulfilled"}
          </h4>
        </Box>
      ) : (
        <FormikProvider value={formik}>
          <Form>
            <Grid container gap={2} sx={{ alignItems: "center" }}>
              <Grid item>
                <TextField
                  label="Amount"
                  margin="normal"
                  name="amount"
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">WETH</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Claim"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </Box>
  );
};

export default Claim;
