import { createState, useState } from "@hookstate/core";

import { fetchUserProfile as dbFetchUserProfile, saveUserProfile } from "src/db/firebase/users";

const defaultSessionState = {
  user: null,
};

const sessionState = createState({ ...defaultSessionState });

export function useUserState() {
  const state = useState(sessionState);

  return {
    get profile() {
      return state.user.value;
    },
    setProfile(walletAddress, { email, username, bio }) {
      state.merge({ user: { email, username, bio } });
      saveUserProfile(walletAddress, { email, username, bio });
    },
    async fetchUserProfile(walletAddress) {
      const profile = await dbFetchUserProfile(walletAddress);
      console.log('profile', profile);
      if (profile) {
        state.merge({ user: { ...profile } });
      }
    },
    logout() {
      state.set({ user: null });
    },
  };
}
