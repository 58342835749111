import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white,
      hero: "#611316",
    },
    primary: {
      contrastText: "#ffffff",
      main: "#611316",
    },
    text: {
      primary: "#172b4d",
      secondary: "#666",
    },
  },
  shadows,
  typography,
});

export default theme;
