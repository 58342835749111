import React, { useContext, useEffect, useRef, useState } from "react";
import Hero from "src/components/frontend/Hero";
import Loading from "src/components/Loading";
import { useListingState, useCollectionState, useSettingState, useProjectsState } from "src/state";
import Collections from "src/components/frontend/Collections";
import Projects from "src/components/frontend/Projects";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { Box } from "@material-ui/core";
import Modal from "src/components/Modal";
import YoutubeVideoPlayer from "src/components/frontend/YoutubeVideoPlayer";
import { pluck } from "src/helpers/array.helper";
import { cachedVersion } from "src/helpers/file.helper";

const HeaderArtwork = () => {
	const { promised: isSettingsLoading, getSetting } = useSettingState();
	if (isSettingsLoading) return null;

	const artwork = getSetting("header_background");
	if (!artwork || artwork === "") return null;

	const isVideo = getSetting("header_background-type");
	if (isVideo === "video") {
		return (
			<Box className="header-artwork" style={{ background: "#b65b24", textAlign: "center" }}>
				<video width="100%" height="100%" style={{ maxWidth: "1100px" }} autoPlay loop muted>
					<source src={cachedVersion(artwork, 'video')} type="video/mp4" />
				</video>
			</Box>
		);
	}

	return <Box className="header-artwork" sx={{ background: `url(${artwork})` }}></Box>;
};

const Home = () => {
	// const blockchainInfo = useContext(BlockchainContext);
	const {
		promised: isListingLoading,
		listings,
		//fetch: fetchListings,
	} = useListingState();
	const { promised: isCollectionsLoading, collections } = useCollectionState();
	const { promised: isProjectsLoading, projects } = useProjectsState();
	const featured = useRef(-1);

	const [openVideoPopup, setOpenVideoPopup] = useState(false);
	const [playingVideo, setPlayingVideo] = useState(false);

	const [listedCollections, setListedCollections] = useState([]);
	const collectionsLoaded = useRef(false);

	// useEffect(() => {
	//   if (blockchainInfo.networkId)
	//     fetchListings(blockchainInfo.networkId);
	// }, [blockchainInfo]);

	useEffect(() => {
		if (!collectionsLoaded.current && !isListingLoading) {
			const collectionWithListings = pluck(listings, "collectionID");
			setListedCollections(
				collections.filter(
					item => item.chainID === 137 && (collectionWithListings.includes(item.address) || item.enabled)
					// blockchainInfo &&
					// (blockchainInfo.networkId
					//   ? item.chainID === blockchainInfo.networkId
					//   : item.chainID === 137)
				) ?? {}
			);
			collectionsLoaded.current = true;
		}
	}, [collections, isListingLoading]);

	// if (isProjectsLoading || isCollectionsLoading) return <Loading />;

	// if (featured.current < 0 && listedCollections.length)
	//   featured.current = Math.floor(Math.random() * listedCollections.length);

	const openVideo = videoURL => {
		setPlayingVideo(videoURL);
		setOpenVideoPopup(true);
	};

	const handleModalClose = () => {
		setOpenVideoPopup(false);
		setPlayingVideo(false);
	};

	return (
		<>
			<HeaderArtwork />

			{listedCollections.length > 0 && (
				<Hero
					listing={{ collectionID: listedCollections[2].address, tokenID: 1 }}
					onClickWatch={videoUrl => openVideo(videoUrl)}
				/>
			)}
			{!process.env.REACT_APP_HAVE_PROJECTS && isCollectionsLoading ? (
				<Loading />
			) : !process.env.REACT_APP_HAVE_PROJECTS && collections.length > 0 ? (
				<Collections collections={listedCollections} title="Our Collections" />
			) : null}
			{process.env.REACT_APP_HAVE_PROJECTS && isProjectsLoading ? (
				<Loading />
			) : process.env.REACT_APP_HAVE_PROJECTS && projects.length > 0 ? (
				<Projects projects={projects} title="Featured Projects" />
			) : null}
			<Modal
				open={openVideoPopup}
				fullWidth={true}
				onClose={handleModalClose}
				content={<YoutubeVideoPlayer youtubeUrl={playingVideo} />}
			/>
		</>
	);
};

export default Home;
