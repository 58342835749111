import { createState, useState, postpone, none } from "@hookstate/core";
import {
  fetchSettings,
  saveSetting,
  deleteSetting,
  setupSettingData,
} from "src/db/firebase/settings";

const settingState = createState(fetchSettings());
settingState.batch((state) => {
  if (state.promised) return postpone;
});

export function useSettingState() {
  const state = useState(settingState);

  return {
    get promised() {
      return state.promised;
    },
    getMarketplaceAddress(networkID) {
      if (state.promised) return null;

      const address = state
        .get()
        .find((item) => item.field === "marketplace_address_" + networkID);

      if (address) return address.value;

      return null;
    },
    async setMarketplaceAddress(networkID, address) {
      await saveSetting("marketplace_address_" + networkID, address);

      state.merge([
        setupSettingData("marketplace_address_" + networkID, {
          value: address,
        }),
      ]);
    },
    getMarketplaceFee(networkID) {
      if (state.promised) return null;

      const fee = state
        .get()
        .find((item) => item.field === "marketplace_fee_" + networkID);
      if (fee) return fee.value;

      return 2.5;
    },
    async setMarketplaceFee(networkID, percentage) {
      await saveSetting("marketplace_fee_" + networkID, percentage);

      state.merge([
        setupSettingData("marketplace_fee_" + networkID, { value: percentage }),
      ]);
    },
    getSetting(key) {
      if (state.promised) return null;
      const result = state.get().find((item) => item.field === key);

      if (result) return result.value;

      return null;
    },
    async setSetting(key, value) {
      await saveSetting(key, value);

      const idx = state.value.findIndex((i) => i.field === key);
      if (idx >= 0) {
        state[idx].merge({ value });
      } else {
        state.merge([setupSettingData(key, { value })]);
      }
    },
    removeSetting(key) {
      const idx = state.value.findIndex((i) => i.field === key);

      if (idx >= 0) {
        state[idx].set(none);
      }

      //send to db
      deleteSetting(key);
    }
  };
}
