import db from "src/plugins/Firebase";

const firestore_collection_name = "listings";

const setupListingData = (dbItem, offers) => {
	const itemData = dbItem.data();

	return {
		listingID: dbItem.id,
		amount: itemData.amount,
		collectionID: itemData.collection_id,
		currency: itemData.currency,
		listingType: itemData.listing_type,
		auctionAddress: itemData.listing_type === 'auction' ? itemData.auction_address : null,
		tokenID: itemData.token_id,
		creator: itemData.creator,
		endDate: itemData.end_date,
		createdAt: itemData.created_at,
		purchased: itemData.purchased ? true : false,
		purchasedBy: itemData.purchasedBy,
		purchasedOn: itemData.purchasedOn,
		purchaseTx: itemData.purchaseTx,
		highest_bid: itemData.highest_bid,
		offers: offers ?? null,
	};
};

const setupOfferData = dbItem => {
	const itemData = dbItem.data();

	return {
		amount: itemData.amount,
		currency: itemData.currency,
		from: itemData.from,
		createdAt: itemData.created_at,
		tx: itemData.tx
	};
};

// Fetch Records
// ToDo:: this should be for token specific only
export const fetchListings = async (chainID = null) => {
	const dbResponse = db.collection(firestore_collection_name);
	const dbData = await dbResponse.get(); //.where('chain_id', chainID ? '==' : '!=', chainID ? chainID : null)

	const data = [];

	dbData.docs.forEach(async item => {
		const offers = [];

		if (item.data().listing_type === "auction") {
			const dbOffers = await db
				.collection(firestore_collection_name)
				.doc(item.id)
				.collection("offers")
        .orderBy('created_at')
				.get();

			dbOffers.docs.forEach(item => {
				offers.push(setupOfferData(item));
			});
		}

		data.push(setupListingData(item, offers));
	});

	return data;
};

export const fetchListing = async listingID => {
	const dbData = await db
		.collection(firestore_collection_name)
		.doc(listingID)
		.get();

	return setupListingData(dbData);
};

export const addNewListing = async data => {
	let dbID = null;

	await db
		.collection(firestore_collection_name)
		.add({
			...data,
			created_at: new Date().getTime(),
		})
		.then(docRef => {
			dbID = docRef.id;
		});

	return dbID;
};

export const updateListing = async (dbID, data) => {
	await db
		.collection(firestore_collection_name)
		.doc(dbID)
		.update({ ...data });
};

export const addNewListingOffer = async (listingID, data) => {
	await db
		.collection(firestore_collection_name)
		.doc(listingID)
		.collection("offers")
		.add({
			amount: data.amount,
			currency: data.currency,
			from: data.from,
			created_at: data.createdAt,
			tx: data.tx
		});
};
