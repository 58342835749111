import { useEffect, useMemo, useReducer } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "./Firebase";

try {
    firebase.initializeApp(firebaseConfig);
} catch (e) {

}

const auth = firebase.auth();

const logInWithEmailAndPassword = async (email, password) => {
	try {
		const res = await auth.signInWithEmailAndPassword(email, password);
        return res.user;
	} catch (err) {
		console.error(err);
	}

    return null;
};

const registerWithEmailAndPassword = async (name, email, password) => {
	try {
		const res = await auth.createUserWithEmailAndPassword(email, password);
		return res.user;
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const sendPasswordReset = async email => {
	try {
		await auth.sendPasswordResetEmail(email);
		alert("Password reset link sent!");
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logout = () => {
	auth.signOut();
};

export { auth, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, logout };
