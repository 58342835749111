import { createState, useState } from "@hookstate/core";
import { logout as firebaseLogout } from "src/plugins/FirebaseAuth";

// get from localstorage if previously logged in
const persistantState = JSON.parse(localStorage.getItem("loggedInAs"));

const defaultSessionState = persistantState
  ? persistantState
  : {
      user: null,
    };

const sessionState = createState({ ...defaultSessionState });

export function useSessionState() {
  const state = useState(sessionState);

  return {
    get loggedInUser() {
      return state.user.value;
    },
    set loggedInUser({ username }) {
      state.merge({ user: { username } });

      //set to localstorage
      localStorage.setItem("loggedInAs", JSON.stringify(state.value));
    },
    logout() {
      state.set({ user: null });

      firebaseLogout();

      //set to localstorage
      localStorage.setItem("loggedInAs", JSON.stringify(state.value));
    },
  };
}
