import { createState, useState, postpone, none } from "@hookstate/core";
import { useErrorState } from "src/state";
import {
  setupProjectData,
  fetchProjects,
  addNewProject,
  deleteProject,
  updateProject,
  incrementCollectionCount as incrementCollectionCountDB
} from "src/db/firebase/projects";

const projectsState = createState(fetchProjects());
projectsState.batch((state) => {
  if (state.promised) return postpone;
});

export function useProjectsState() {
  const state = useState(projectsState);
  const { setErrorMessage } = useErrorState();

  return {
    get promised() {
      return state.promised;
    },
    get projects() {
      return !state.promised ? state.get() : [];
    },
    getProject(projectID) {
      const project = !state.promised
        ? state.get().find((item) => item.id === projectID)
        : {};

      return project;
    },
    async createProject(data) {
      data.created_at = new Date().getTime();
      
      //send to db
      const dbID = await addNewProject(data);

      state.merge([setupProjectData(dbID, data)]);

      return dbID;
    },
    updateProject(projectID, updatedData) {
      if (!state.promised) {
        const idx = state.findIndex((i) => i.id.get() === projectID);
        if (idx >= 0) {
          state[idx].merge(updatedData);
        }

        updateProject(projectID, updatedData);
      }
    },
    removeProject(projectID) {
      const idx = state.value.findIndex((i) => i.id === projectID);

      if (idx < 0 || state[idx].listingCount.get() > 0) {
        setErrorMessage("Can not delete a project that already got listings");
        return;
      }

      if (idx >= 0) {
        state[idx].set(none);
      }

      //send to db
      //deleteProject(projectID);
    },
    incrementCollectionCount(projectID) {
      if (!state.promised) {
        const idx = state.findIndex((i) => i.id.get() === projectID);
        if (idx >= 0) {
          state[idx].collection_count.set((p) => p + 1);
        }

        incrementCollectionCountDB(projectID);
      }
    }
  };
}
