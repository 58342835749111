import { cachedVersion } from 'src/helpers/file.helper';
import { useSettingState } from 'src/state';

const Logo = (props) => {
  const {getSetting} = useSettingState();
  const logoURL = getSetting('website_logo');
  return logoURL ? (
    <img
      alt="Logo"
      src={cachedVersion(logoURL)}
      style={{ maxWidth: "150px" }}
      {...props}
    />
  ) : null
};

export default Logo;
