import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { useUserState, useGlobalState } from "src/state";
import { BlockchainContext } from "src/providers/BlockchainProvider";

const Settings = () => {
  const navigate = useNavigate();
  const { setProfile, profile } = useUserState();
  const { getRedirectToAfterConnect, setRedirectToAfterConnect } = useGlobalState();

  const blockchainInfo = useContext(BlockchainContext);

  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (!blockchainInfo.account) {
      navigate("/connect-wallet");
    }
  }, [blockchainInfo]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              ...profile,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              username: Yup.string().max(255).required("Username is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setProfile(blockchainInfo.account, { ...values });
              setSubmitting(false);
              setSuccessMessage("Profile Updated!");

              const redirectTo = getRedirectToAfterConnect();
              if (redirectTo) {
                setRedirectToAfterConnect(null);
                navigate(redirectTo, { replace: true });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" align="center" variant="h2">
                    My Profile
                  </Typography>
                  {getRedirectToAfterConnect() ? (
                    <Typography
                      color="textSecondary"
                      align="center"
                      gutterBottom
                      variant="body2"
                    >
                      Update your public profile in order to buy NFT
                    </Typography>
                  ) : (
                    <Typography
                      color="textSecondary"
                      align="center"
                      gutterBottom
                      variant="body2"
                    >
                      Update your public profile
                    </Typography>
                  )}
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Bio"
                  margin="normal"
                  name="bio"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bio}
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={
                      isSubmitting ||
                      !touched.email ||
                      !touched.username ||
                      (errors.email && errors.email.length > 0) ||
                      (errors.username && errors.username.length > 0)
                    }
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save Profile
                  </Button>
                </Box>
                {successMessage && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Settings;
