import { Box, Container, Typography } from "@material-ui/core";

const ComingSoon = () => (
  <>
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          This section is coming soon!
        </Typography>
      </Container>
    </Box>
  </>
);

export default ComingSoon;
