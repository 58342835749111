import { useEffect, useRef, useState, useContext, useCallback } from "react";
import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import {
	Box,
	Button,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Switch,
	Typography,
	Grid,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import jsonObjectTemplate from "src/metadata/ERC721-universal";
import { storage } from "src/plugins/Firebase";
import TextField from "src/components/FormikTextField";
import { isValidAddress, networkName, shortAccount } from "src/helpers/blockchain.helper";
import { hash_to_ipfs_link } from "src/helpers/links.helper";
import ImagePicker from "src/components/ImagePicker";
import { toSlug } from "src/helpers/string.helper";
import Modal from "src/components/Modal";
import PropertyEditor from "src/components/collection/PropertyEditor";
import TokenAttribute from "src/components/collection/TokenAttribute";

const ST = require("stjs");

const ItemForm = ({ defaultData, projectData, onCreateItem, toValidateSku, isForFrontend, ...props }) => {
	const blockchainInfo = useContext(BlockchainContext);
	const [selectedImage, setSelectedImage] = useState("");
	const [selectedAnimationFile, setSelectedAnimationFile] = useState("");
	const [haveAnimation, setHaveAnimation] = useState(false);
	const [showPropertyEditor, setShowPropertyEditor] = useState(false);
	const [selectedProperties, setSelectedProperties] = useState(defaultData && defaultData.attributes ? [...defaultData.attributes] : []);

	const selectedImageBuffer = useRef(null);
	const selectedAnimationBuffer = useRef(null);
	const selectedAnimationFileType = useRef(null);

	useEffect(() => {
		return function () {
			if (selectedImage) {
				URL.revokeObjectURL(selectedImage);
				selectedImageBuffer.current = null;
			}
		};
	}, [selectedImage]);

	const togglePropertyEditor = () => setShowPropertyEditor(!showPropertyEditor);

	const handleFileSelect = (filename, file) => {
		if (file && file.type.match("^image/")) {
			readBlobToBuffer(file).then(imageBuffer => (selectedImageBuffer.current = imageBuffer));
			setSelectedImage(URL.createObjectURL(file));
		}
	};

	const handleAnnimationSelect = (filename, file) => {
		if (file && (file.type.match("^image/gif") || file.type.match("^video/"))) {
			readBlobToBuffer(file).then(imageBuffer => (selectedAnimationBuffer.current = imageBuffer));
			setSelectedAnimationFile(URL.createObjectURL(file));

			const fileType = file.type.match("^video/") ? "video" : "image";
			selectedAnimationFileType.current = fileType;
		}
	};

	const handleGotAnimation = () => setHaveAnimation(!haveAnimation);

	const handleSaveProperties = properties => {
		setShowPropertyEditor(false);
		const selected = [];
		properties.map(property => {
			if (property.title !== "" && property.value !== "") selected.push(property);
		});
		setSelectedProperties(selected);
	};

	const formik = useFormik({
		initialValues: {
			item_sku: "",
			item_name: defaultData ? defaultData.item_name : "",
			description: defaultData ? defaultData.description : "",
			external_url: defaultData ? defaultData.external_url : "",
			wallet_address: "",
		},
		onSubmit: async (values, formikHelpers) => {
			const jsonObj = ST.select(values).transformWith(jsonObjectTemplate).root();

			if (selectedProperties && selectedProperties.length > 0) {
				selectedProperties.map(property => {
					jsonObj.attributes.push({
						key: toSlug(property.title),
						trait_type: property.title,
						value: property.value,
					});
				});
			}

			try {
				const imageHash = await uploadFileToIPFS(selectedImageBuffer.current);

				let animationHash = null;
				if (haveAnimation && selectedAnimationBuffer.current) {
					animationHash = await uploadFileToIPFS(selectedAnimationBuffer.current);
					jsonObj.animation_url = hash_to_ipfs_link(animationHash);
				}

				// add the image url to metadata
				jsonObj.image = hash_to_ipfs_link(imageHash);

				//cache image on storage as well
				//storage.ref(`/ipfs/${imageHash}`).put(selectedImageBuffer.current);

				// upload the metadata to ipfs
				const jsonBuffer = Buffer.from(JSON.stringify(jsonObj));
				const jsonHash = await uploadFileToIPFS(jsonBuffer);

				formikHelpers.setSubmitting(false);
				// return back the data to parent page
				onCreateItem &&
					onCreateItem({
						item_sku: values.item_sku,
						metadata_hash: jsonHash,
						image_url: jsonObj.image,
						animation_url: jsonObj.animation_url ?? null,
						animation_file_type: selectedAnimationFileType.current,
						metadata: {...values, attributes: selectedProperties},
						wallet_address: values.wallet_address,
						//certificate_hash: certificateHash,
					});
			} catch (error) {
				formikHelpers.setSubmitting(false);
				console.error(error);
				return;
			}
		},
		validationSchema: Yup.object().shape({
			item_sku: Yup.string()
				.max(30)
				.required("Please fill in the Item SKU")
				.test("item_sku", "This SKU has already been added", function (item_sku) {
					return !toValidateSku || toValidateSku(item_sku);
				}),
			item_name: Yup.string().max(100).required("Item Name is required"),
			wallet_address: Yup.string().test("is_valid", "This address is not valid", function (value) {
				return !value || isValidAddress(value);
			}),
			external_url: Yup.string().url("Please provide a valid URL (including http or https)"),
		}),
	});

	return (
		<>
			<FormikProvider value={formik}>
				<Form>
					<Typography mb={2} variant="h5">
						Please ensure the details you adding are correct, as these can not be modified once created
					</Typography>
					<ImagePicker
						withDragDrop
						name="project_image"
						onFileSelect={handleFileSelect}
						value={selectedImage}
						buttonProps={{ color: "primary", variant: "contained" }}
					/>
					<FormGroup>
						<FormControlLabel
							control={<Switch onChange={handleGotAnimation} />}
							label="Have animation or video?"
						/>
					</FormGroup>
					{haveAnimation && (
						<ImagePicker
							withDragDrop
							name="animation_file"
							onFileSelect={handleAnnimationSelect}
							value={selectedAnimationFile}
							buttonProps={{ color: "primary", variant: "contained" }}
							accept="image/gif, video/*"
						/>
					)}
					<TextField
						fullWidth
						helperText="This must be a unique code, as the NFT will be created based on this"
						label="SKU"
						margin="normal"
						name="item_sku"
						type="text"
						variant="outlined"
					/>
					<TextField
						fullWidth
						label="Item Name"
						margin="normal"
						name="item_name"
						type="text"
						variant="outlined"
					/>
					<TextField
						fullWidth
						label="External URL"
						margin="normal"
						name="external_url"
						type="text"
						variant="outlined"
						helperText="We will include a link to this URL on this item's detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more details."
					/>
					<TextField
						fullWidth
						multiline
						rows={4}
						label="Description"
						margin="normal"
						name="description"
						variant="outlined"
						helperText="The description will be included on the item's detail page underneath its image."
					/>
					<Grid container sx={{ justifyContent: "space-between" }}>
						<Grid item>
							<Typography variant="h3">Properties</Typography>
							<Typography variant="body2">Textual traits that show up as rectangles</Typography>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={togglePropertyEditor}>
								<AddIcon />
							</Button>
						</Grid>
					</Grid>
					{selectedProperties.length > 0 && (
						<Grid
							container
							spacing={2}
							sx={{
								justifyContent: "flex-start",
								my: 1,
							}}
						>
							{selectedProperties.map((property, index) => (
								<Grid key={index} item xs={6} sm={4} md={3}>
									<TokenAttribute title={property.title} value={property.value} />
								</Grid>
							))}
						</Grid>
					)}
					{!isForFrontend && (
						<TextField
							fullWidth
							label="Mint on behalf of another Wallet Address"
							helperText="If specified, the NFT will be owned by the given wallet address"
							margin="normal"
							name="wallet_address"
							type="text"
							variant="outlined"
						/>
					)}
					<Box sx={{ pt: 2 }}>
						<Button
							color="primary"
							disabled={
								formik.isSubmitting ||
								!formik.isValid ||
								(!formik.touched.item_sku && !formik.isInitialValid)
							}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							{formik.isSubmitting ? <CircularProgress size={20} /> : "Create"}
						</Button>
					</Box>
					<Typography variant="caption" component="p" sx={{ mt: 2 }} color="error" align="center">
						You are connected to {networkName(blockchainInfo ? blockchainInfo.networkId : 0)} with account{" "}
						{shortAccount(blockchainInfo ? blockchainInfo.account : null)}
					</Typography>
				</Form>
			</FormikProvider>
			<Modal
				open={showPropertyEditor}
				onClose={togglePropertyEditor}
				title="Add Properties"
				content={<PropertyEditor properties={selectedProperties} onSave={handleSaveProperties} />}
			/>
		</>
	);
};
export default ItemForm;
