import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { useEffect, useState } from "react";
import { useCollectionState, useListingState, useSettingState } from "src/state";
import ListingItem from "../ListingItem";
import YoutubeVideoPlayer from "../YoutubeVideoPlayer";

const HeaderModule = () => {
	const { getSetting } = useSettingState();
	const { promised: isLoading, getActiveListingForCollection } = useListingState();
	const { promised: isLoadingCollections, getCollection } = useCollectionState();

	const videoURL = getSetting("header_video");
	const highlightedCollectionAddress = getSetting("highlighted_collection");

	const [highlightedCollection, setHighlightedCollection] = useState(null);
	const [highlightedListing, setHighlightedListing] = useState(null);

	useEffect(() => {
		if (!isLoading && highlightedCollectionAddress && highlightedCollectionAddress !== "") {
			const listing = getActiveListingForCollection(highlightedCollectionAddress);
			if (listing) {
				setHighlightedListing(listing);
			}
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLoadingCollections && highlightedCollectionAddress && highlightedCollectionAddress !== "") {
			const collection = getCollection(highlightedCollectionAddress);
			if (collection) {
				setHighlightedCollection(collection);
			}
		}
	}, [isLoadingCollections]);

	return (
		<Box sx={{ mx: {xs: 2, md: 0}, my: {xs: 3, md: 0} }}>
			<Grid container rowGap={3}>
				<Grid item xs={12} md={6}>
					{highlightedListing && (
						<ListingItem
							listing={highlightedListing}
							gridOptions={{ xs: 12, sm: 6, md: 4 }}
							name={highlightedCollection ? highlightedCollection.name : null}
							description={highlightedCollection ? highlightedCollection.symbol : null}
							showEmpty={true}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					{videoURL && videoURL !== "" && (
						<Box sx={{ maxWidth: "615px", margin: "0 0 0 auto" }}>
							<YoutubeVideoPlayer youtubeUrl={videoURL} />
						</Box>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default HeaderModule;
