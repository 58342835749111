import * as Yup from "yup";
import { Form, useFormik, FormikProvider } from "formik";
import TextField from "src/components/FormikTextField";
import { Button, Chip, FormGroup, Stack, Typography } from "@material-ui/core";

const BidForm = ({ minAmount, currency, onPlaceBid }) => {
	const formik = useFormik({
		initialValues: {
			amount: minAmount,
		},
		onSubmit: async (values, formikHelpers) => {
			onPlaceBid && onPlaceBid(values);
		},
		validationSchema: Yup.object().shape({
			amount: Yup.number()
				.typeError("Please provide a valid bid amount")
				.min(minAmount)
				.required("Please provide your bid amount"),
		}),
	});

	const setAmount = amount => {};

	return (
		<FormikProvider value={formik}>
			<Form>
				<FormGroup>
					<Typography mb={2} variant="h5">
						Please specify your bid amount
					</Typography>
					<TextField
						fullWidth
						helperText={`This must be ${currency} ${minAmount} or higher`}
						label="Bid Amount"
						margin="normal"
						name="amount"
						type="text"
						variant="outlined"
					/>
				</FormGroup>
				<FormGroup>
					<Typography mt={2} variant="h4">
						Recommended Bids
					</Typography>
					<Stack direction="row" my={2} spacing={1}>
						<Chip
							label={`${currency} ${Math.round((minAmount + 0.25) * 100) / 100}`}
							onClick={() =>
								formik.setFieldValue("amount", Math.round((minAmount + 0.25) * 100) / 100)
							}
						/>
						<Chip
							label={`${currency} ${Math.round((minAmount + 0.50) * 100) / 100}`}
							onClick={() =>
								formik.setFieldValue("amount", Math.round((minAmount + 0.50) * 100)/ 100)
							}
						/>
						<Chip
							label={`${currency} ${Math.round((minAmount + 0.75) * 100)/ 100}`}
							onClick={() =>
								formik.setFieldValue("amount", Math.round((minAmount + 0.75) * 100)/ 100)
							}
						/>
					</Stack>
				</FormGroup>
				<Button
					color="primary"
					disabled={
						formik.isSubmitting ||
						(formik.touched.amount && !formik.isValid)
					}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
				>
					Send
				</Button>
			</Form>
		</FormikProvider>
	);
};

export default BidForm;
