import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import BlockchainProvider from "src/providers/BlockchainProvider";
import GlobalStyles from "src/components/GlobalStyles";
import Modal from "src/components/Modal";
import theme from "src/themes/default";
import routes from "src/routes";
import { useErrorState, useSessionState } from "src/state";
import "./App.css";

const App = () => {
  const { loggedInUser } = useSessionState();

  const routing = useRoutes(routes(loggedInUser));
  const { isError, errorMessage, clearError } = useErrorState();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <BlockchainProvider>{routing}</BlockchainProvider>
      <Modal
        open={isError}
        onClose={clearError}
        title="Sorry!"
        summary={errorMessage}
        showOkButton={true}
      />
    </ThemeProvider>
  );
};

export default App;
