import { Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import BareLayout from "./layouts/BareLayout";
import Login from "./pages/access/Login";
import NotFound from "./pages/NotFound";
import ComingSoon from "./pages/ComingSoon";
import AdminDashboard from "./pages/admin/Dashboard";
import AdminCollections from "./pages/admin/collections";
import AdminCollectionTokens from "./pages/admin/collections/Tokens";
import AdminCollectionTokenSell from "./pages/admin/collections/Sell";
import AdminCategories from "./pages/admin/Categories";
import AdminSettings from "./pages/admin/Settings";
import AdminUsers from "./pages/admin/Users";

import AdminProjects from "./pages/admin/projects";
import AdminProjectsForm from "./pages/admin/projects/Form";

import Listing from "./pages/marketplace/Listing";
import Home from "./pages/marketplace/Home";
import SearchResults from "./pages/marketplace/SearchResults";
import Collection from "./pages/marketplace/Collection";
import AddToken from "./pages/marketplace/AddToken";
import Project from "./pages/marketplace/Project";
import ConnectWallet from "./pages/access/Connect";

import ProfileSettings from "./pages/profile/settings";
import ProfileFavourites from "./pages/profile/favourites";
import ProfileCollections from "./pages/profile/collections";

import Claim from "./pages/demo/Claim";
import Settle from "./pages/demo/Settle";

export const admin_base_path = "/admin";
export const listing_base_path = "/listing";

const routes = loggedInUser => [
	{
		path: "admin",
		element: loggedInUser ? <DashboardLayout /> : <Navigate to="/login" />,
		children: [
			{ path: "/", element: <Navigate to="dashboard" replace={true} /> },
			{ path: "/dashboard", element: <AdminDashboard /> },
			{ path: "/collections", element: <AdminCollections /> },
			{
				path: "/collections/:id",
				children: [
					{ path: "/tokens", element: <AdminCollectionTokens /> },
					{ path: "/tokens/list/:tokenId", element: <AdminCollectionTokenSell /> },
				],
			},
			{
				path: "/projects",
				children: [
					{ path: "/", element: <AdminProjects /> },
					{ path: "/new", element: <AdminProjectsForm /> },
					{ path: "/edit/:id", element: <AdminProjectsForm /> },
				],
			},
			{
				path: "/categories",
				element: <AdminCategories />,
			},
			{
				path: "/users",
				element: <AdminUsers />,
			},
			{
				path: "/transactions",
				element: <Navigate to="/coming-soon" replace={true} />,
			},
			{
				path: "/settings",
				element: <AdminSettings />,
			},
			{ path: "*", element: <Navigate to="/404" replace={true} /> },
		],
	},
	{
		path: "/login",
		element: <BareLayout />,
		children: [{ path: "/", element: <Login /> }],
	},
	{
		path: "/404",
		element: <BareLayout />,
		children: [
			{ path: "/", element: <NotFound /> },
			{ path: "*", element: <Navigate to="/404" replace={true} /> },
		],
	},
	{
		path: "/coming-soon",
		element: <BareLayout />,
		children: [
			{ path: "/", element: <ComingSoon /> },
			{ path: "*", element: <Navigate to="/coming-soon" replace={true} /> },
		],
	},
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/listing/:cid/:id",
				element: <Listing />,
			},
			{
				path: "/search/:keyword",
				element: <SearchResults />,
			},
			{
				path: "/collection/:cid",
				children: [
					{ path: "/", element: <Collection /> },
					{ path: "/add-item", element: <AddToken /> },
				],
			},
			{
				path: "/project/:pid",
				element: <Project />,
			},
			{
				path: "connect-wallet",
				element: <ConnectWallet />,
			},
			{
				path: "profile/settings",
				element: <ProfileSettings />,
			},
			{
				path: "profile/favourites",
				element: <ProfileFavourites />,
			},
			{
				path: "profile/collections",
				element: <ProfileCollections />,
			},
			{ path: "*", element: <Navigate to="/404" replace={true} /> },
		],
	},
	{
		path: "/demo",
		element: <MainLayout />,
		children: [
			{
				path: "/",
				element: <Claim />,
			},
			{
				path: "/settle",
				element: <Settle />,
			},
		],
	},
];

export default routes;
