import db from "src/plugins/Firebase";

const firestore_collection_name = "users";

const setupUserData = (item) => {
  const itemData = item.data();
  return {
    id: item.id,
    username: itemData?.username ?? "",
    email: itemData?.email ?? "",
    bio: itemData?.bio ?? ""
  };
};

// Fetch Records
export const fetchUserProfile = async (walletAddress) => {
  const dbData = await db
    .collection(firestore_collection_name)
    .doc(walletAddress)
    .get();

  return setupUserData(dbData)
};

export const saveUserProfile = async (walletAddress, data) => {
  if (!walletAddress) return;

  var docRef = db.collection(firestore_collection_name).doc(walletAddress);

  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        db.collection(firestore_collection_name).doc(walletAddress).update(data);
      } else {
        db.collection(firestore_collection_name).doc(walletAddress).set({
          ...data,
          created_at: new Date().getTime()
        });
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const fetchUsers = async () => {
  const dbResponse = db.collection(firestore_collection_name).orderBy('username', 'asc');
  const dbData = await dbResponse.get();

  const data = [];

  dbData.docs.forEach((item) => {
    data.push(setupUserData(item));
  });

  return data;
}