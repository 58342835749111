import { createState, useState } from "@hookstate/core";

const defaultErrorState = {
  showError: false,
  errorMessage: "",
};

const errorState = createState({ ...defaultErrorState });

export function useErrorState() {
  const state = useState(errorState);

  return {
    get isError() {
      return state.showError.value;
    },
    get errorMessage() {
      return state.errorMessage.value;
    },
    setErrorMessage(message) {
      state.set({
        showError: true,
        errorMessage: message,
      });
    },
    clearError() {
      state.set({ ...defaultErrorState });
    },
  };
}
