import { Box } from "@material-ui/core";
import { useState } from "react";
import { youtube_embed_link } from "src/helpers/links.helper";
import Loading from "../Loading";

const YoutubeVideoPlayer = ({ youtubeUrl }) => {
  const [loaded, setLoaded] = useState(false);

  const videoLoaded = () => {
    setLoaded(true);
  };

  return (
    <Box>
      {!loaded && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </Box>
      )}
      <iframe
        id="ytplayer"
        type="text/html"
        width="100%"
        height="360"
        src={youtube_embed_link(youtubeUrl)}
        frameborder="0"
        onLoad={videoLoaded}
        allow="fullscreen;"
      ></iframe>
    </Box>
  );
};

export default YoutubeVideoPlayer;
