import { Link } from "react-router-dom";

export const LinkableExternal = ({ isLinked, to, ...props }) =>
  isLinked ? (
    <a href={to} target="_blank" rel="noreferrer" {...props}>
      {props.children}
    </a>
  ) : (
    <>{props.children}</>
  );

export const LinkableLocal = ({ isLinked, to, ...props }) =>
  !!isLinked && to ? (
    <Link to={to}>{props.children}</Link>
  ) : (
    <>{props.children}</>
  );
