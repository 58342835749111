import { Box, experimentalStyled } from "@material-ui/core";
import { useSettingState } from "src/state";

const MainFooterRoot = experimentalStyled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "25px",
  borderTop: "1px solid #eee",
  padding: "25px 0",
  fontSize: "12px",
  color: "#333",
}));

const MainFooter = () => {
  const { promised: isSettingsLoading, getSetting } = useSettingState();

  return isSettingsLoading ? null : (
    <MainFooterRoot>
      <Box className="container">
        <p>{getSetting("footer_text")}</p>
      </Box>
    </MainFooterRoot>
  );
};

export default MainFooter;
