import React, { useState, useContext, useEffect } from "react";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Checkbox,
	Container,
	FormControl,
	Grid,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import Tabs from "src/components/Tabs";
import Loading from "src/components/Loading";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { deploy, updateMarketplaceFee } from "src/plugins/Ethereum";
import { useCollectionState, useProjectsState, useSettingState } from "src/state";
import { networkName } from "src/helpers/blockchain.helper";
import ImagePicker from "src/components/ImagePicker";
import { hash_to_ipfs_link } from "src/helpers/links.helper";
import { readBlobToBuffer, uploadFileToIPFS } from "src/helpers/file.helper";
import { MAINNETS } from "src/constants/Mainnets";

const Settings = () => {
	const {
		promised: isLoading,
		getMarketplaceAddress,
		setMarketplaceAddress,
		getMarketplaceFee,
		setMarketplaceFee,
		getSetting,
		setSetting,
		removeSetting,
	} = useSettingState();

	const { promised: isProjectsLoading, projects } = useProjectsState();
	const { promised: isCollectionLoading, collections, getCollection } = useCollectionState();

	const [isDeploying, setIsDeploying] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const blockchainInfo = useContext(BlockchainContext);

	const marketplaceAddress = blockchainInfo ? getMarketplaceAddress(blockchainInfo.networkId) : null;
	const marketplaceFee = blockchainInfo ? getMarketplaceFee(blockchainInfo.networkId) : 2.5;

	const handleDeploy = () => {
		if (isDeploying) return;
		setIsDeploying(true);

		deploy("marketplace", [250]) // deploy with default 2.5% fee
			.then(result => {
				setMarketplaceAddress(blockchainInfo.networkId, result.address);
			})
			.catch(error => {})
			.finally(() => setIsDeploying(false));
		//setMarketplaceAddress("0x3599f184786fd045db7424707550f363609B510F");
	};

	const handleFeeUpdate = ({ marketplace_fee }) => {
		if (isDeploying) return;
		setIsDeploying(true);

		updateMarketplaceFee(marketplaceAddress, marketplace_fee)
			.then(() => setMarketplaceFee(blockchainInfo.networkId, marketplace_fee))
			.finally(() => setIsDeploying(false));
	};

	const handleFileSelect = (filename, file) => {
		setIsSaving(true);
		//upload the file to IPFS
		try {
			if (file) {
				readBlobToBuffer(file).then(async fileBuffer => {
					const fileHash = await uploadFileToIPFS(fileBuffer);
					const value = hash_to_ipfs_link(fileHash);

					setSetting(filename, value);

					//if this is a video
					if (file.type.search(/video/i) >= 0) setSetting(`${filename}-type`, "video");
					else removeSetting(`${filename}-type`);

					setIsSaving(false);
				});
			} else {
				setIsSaving(false);
			}
		} catch (e) {
			console.log(e);
			setIsSaving(false);
		}
	};

	const handleSettingDelete = key => {
		removeSetting(key);
	};

	const connect = () => {
		if (blockchainInfo) blockchainInfo.connectToBlockchain();
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "background.default",
					minHeight: "100%",
					py: 3,
				}}
			>
				<Container maxWidth={false}>
					{isLoading ? (
						<Loading />
					) : (
						<Tabs>
							<Box label="Home Page">
								<Formik
									enableReinitialize
									initialValues={{
										highlighted_project: "",
										highlighted_collection: getSetting("highlighted_collection") ?? "",
										header_video: getSetting("header_video") ?? "",
										show_projects: 0,
										show_collections: 0,
									}}
									onSubmit={(values, actions) => {
										setSetting("header_video", values.header_video);
										setSetting("highlighted_collection", values.highlighted_collection);
									}}
								>
									{({
										errors,
										handleBlur,
										handleChange,
										handleSubmit,
										isSubmitting,
										touched,
										setFieldValue,
										values,
									}) => (
										<form onSubmit={handleSubmit}>
											<Typography variant="h3" mb={2}>
												{process.env.REACT_APP_HAVE_PROJECTS
													? "Highlighted Project"
													: "Highlighted Collection"}
											</Typography>
											{process.env.REACT_APP_HAVE_PROJECTS && !isProjectsLoading && (
												<Select
													fullWidth
													name="highlighted_project"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.project_id}
													sx={{ mb: 2 }}
												>
													{projects.map((item, index) => (
														<MenuItem key={index} value={item.id}>
															{item.project_name}
														</MenuItem>
													))}
												</Select>
											)}
											{!process.env.REACT_APP_HAVE_PROJECTS && !isCollectionLoading && (
												<Autocomplete
													fullWidth
													disablePortal
													id="highlighted_collection"
													// options={collections.filter((item) =>
													//   MAINNETS.includes(item.chainID)
													// )}
													options={collections}
													getOptionLabel={option => `${option.symbol} - ${option.name}`}
													defaultValue={getCollection(values.highlighted_collection)}
													sx={{ width: 300 }}
													renderInput={params => (
														<TextField fullWidth {...params} label="Collection" />
													)}
													onChange={(e, collection) => {
														if (collection)
															setFieldValue("highlighted_collection", collection.address);
														else setFieldValue("highlighted_collection", "");
													}}
												/>
											)}
											{process.env.REACT_APP_HAVE_PROJECTS && (
												<Box sx={{ display: "flex", alignItems: "center" }}>
													<Checkbox onChange={handleChange} name="show_projects" />
													<Typography>Show recently added Projects</Typography>
												</Box>
											)}
											{!process.env.REACT_APP_HAVE_PROJECTS && (
												<Box sx={{ display: "flex", alignItems: "center" }}>
													<Checkbox onChange={handleChange} name="show_collections" />
													<Typography>Show recently added Collections</Typography>
												</Box>
											)}
											<Box sx={{ display: "flex", alignItems: "center" }}>
												<Checkbox onChange={handleChange} name="show_categories" />
												<Typography>
													Show categories (categories with atleast one listing will appear)
												</Typography>
											</Box>
											<Box my={3}>
												<Typography variant="h3">Header Video URL</Typography>
												<TextField
													fullWidth
													margin="normal"
													name="header_video"
													type="text"
													variant="outlined"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.header_video}
													sx={{ maxWidth: "400px" }}
												/>
											</Box>
											<Box my={3}>
												<Button variant="contained" type="submit">
													Save
												</Button>
											</Box>
										</form>
									)}
								</Formik>
							</Box>
							<Box label="Design">
								<Grid container>
									<Grid item md={3}>
										<Typography variant="h3" mb={2}>
											Website Logo
										</Typography>
										<ImagePicker
											withDragDrop
											singleColumn
											name="website_logo"
											onFileSelect={handleFileSelect}
											value={getSetting("website_logo")}
											recommendedSize="240 x 126"
											onDelete={handleSettingDelete}
										/>
									</Grid>
									{/* <Grid item md={3}>
                    <Typography variant="h3" mb={2}>Website Icon</Typography>
                    <ImagePicker
                      withDragDrop
                      singleColumn
                      name="website_icon"
                      onFileSelect={handleFileSelect}
                      value={getSetting('website_icon')}
                      recommendedSize="64 x 64"
                    />
                  </Grid> */}
									<Grid item md={3}>
										<Typography variant="h3" mb={2}>
											Header Background
										</Typography>
										<ImagePicker
											withDragDrop
											singleColumn
											accept="image/*|video/mp4"
											name="header_background"
											onFileSelect={handleFileSelect}
											value={getSetting("header_background")}
											isVideo={getSetting("header_background-type") === "video"}
											recommendedSize="1440 x 425"
											onDelete={handleSettingDelete}
										/>
									</Grid>
									<Grid item md={3}>
										<Typography variant="h3" mb={2}>
											Page Background
										</Typography>
										<ImagePicker
											withDragDrop
											singleColumn
											name="body_background"
											onFileSelect={handleFileSelect}
											value={getSetting("body_background")}
											recommendedSize="300 x 300"
											onDelete={handleSettingDelete}
										/>
									</Grid>
								</Grid>
							</Box>
							<Box label="Default Text">
								<Formik
									enableReinitialize
									initialValues={{
										footer_text: getSetting("footer_text") ?? "",
										default_owner: getSetting("default_owner") ?? "",
										primary_color: getSetting("primary_color") ?? "",
									}}
									onSubmit={(values, actions) => {
										setSetting("footer_text", values.footer_text);
										setSetting("default_owner", values.default_owner);
										setSetting("primary_color", values.primary_color);
										console.log(values);
									}}
								>
									{({
										errors,
										handleBlur,
										handleChange,
										handleSubmit,
										isSubmitting,
										touched,
										values,
									}) => (
										<form onSubmit={handleSubmit}>
											<Box sx={{ maxWidth: "300px" }}>
												<TextField
													fullWidth
													label="Footer Text"
													margin="normal"
													name="footer_text"
													type="text"
													variant="outlined"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.footer_text}
												/>
												<TextField
													fullWidth
													label="Default Owner"
													margin="normal"
													name="default_owner"
													type="text"
													variant="outlined"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.default_owner}
												/>
												<TextField
													fullWidth
													label="Primary Color"
													margin="normal"
													name="primary_color"
													type="text"
													variant="outlined"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.primary_color}
												/>
												<Box mt={2}>
													<Button color="primary" type="submit" variant="contained">
														Save
													</Button>
												</Box>
											</Box>
										</form>
									)}
								</Formik>
							</Box>
							<Box label="Marketplace Config">
								{blockchainInfo.networkId ? (
									<Box>
										<Typography variant="h2">Marketplace Contract</Typography>
										<Typography variant="caption" component="p" sx={{ mb: 2 }} color="error">
											You are connected to{" "}
											{networkName(blockchainInfo ? blockchainInfo.networkId : 0)}
										</Typography>
										{marketplaceAddress && (
											<>
												<Typography>{marketplaceAddress}</Typography>
												{true && (
													<>
														<Button
															variant="contained"
															color="primary"
															onClick={handleDeploy}
														>
															Redeploy
														</Button>
														{isDeploying && <CircularProgress size={20} sx={{ ml: 1 }} />}
													</>
												)}
											</>
										)}
										{!marketplaceAddress && (
											<>
												<Button variant="contained" color="primary" onClick={handleDeploy}>
													Deploy
												</Button>
												{isDeploying && <CircularProgress size={20} sx={{ ml: 1 }} />}
											</>
										)}
										{marketplaceAddress && (
											<Box sx={{ mt: 3 }}>
												<Formik
													enableReinitialize
													initialValues={{
														marketplace_fee: marketplaceFee,
													}}
													validationSchema={Yup.object().shape({
														marketplace_fee:
															Yup.number().required("Please provide a percentage"),
													})}
													onSubmit={(values, actions) => {
														actions.setSubmitting(false);
														handleFeeUpdate(values);
													}}
												>
													{({
														errors,
														handleBlur,
														handleChange,
														handleSubmit,
														isSubmitting,
														touched,
														values,
													}) => (
														<form onSubmit={handleSubmit}>
															<FormControl component="fieldset">
																<TextField
																	helperText="The fee that needs to be deducted for each sales"
																	label="Marketplace Fee %"
																	margin="normal"
																	name="marketplace_fee"
																	type="number"
																	variant="outlined"
																	onBlur={handleBlur}
																	onChange={handleChange}
																	value={values.marketplace_fee}
																/>
																<Box sx={{ pt: 2 }}>
																	<Button
																		color="primary"
																		disabled={isSubmitting}
																		size="large"
																		type="submit"
																		variant="contained"
																	>
																		Save
																	</Button>
																	{isDeploying && (
																		<CircularProgress size={20} sx={{ ml: 1 }} />
																	)}
																</Box>
															</FormControl>
														</form>
													)}
												</Formik>
											</Box>
										)}
									</Box>
								) : (
									<Box>
										<Typography mb={2}>Please connect to your blockchain network first</Typography>
										<Button variant="contained" color="primary" onClick={connect}>
											Connect
										</Button>
									</Box>
								)}
							</Box>
						</Tabs>
					)}
					{isSaving && <Loading />}
				</Container>
			</Box>
		</>
	);
};

export default Settings;
