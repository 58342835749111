import { createState, useState } from "@hookstate/core";

const globalState = createState({
  isListingModalVisible: false,
  creatingListingFor: null,
  redirectToAfterConnect: null
});

export function useGlobalState() {
  const state = useState(globalState);

  return {
    get isListingModalVisible() {
      return state.isListingModalVisible.get();
    },
    toggleListingModalVisible() {
      state.isListingModalVisible.set((p) => !p);
    },
    get listingForToken() {
      return state.creatingListingFor.get();
    },
    setListingForToken(tokenInfo) {
      state.creatingListingFor.set(tokenInfo);
    },
    get isTransferModalVisible() {
      return state.isTransferModalVisible.get();
    },
    toggleTransferModalVisible() {
      state.isTransferModalVisible.set((p) => !p);
    },
    get transferForToken() {
      return state.creatingTransferFor.get();
    },
    setTransferForToken(tokenInfo) {
      state.creatingTransferFor.set(tokenInfo);
    },
    getRedirectToAfterConnect() {
      return state.redirectToAfterConnect.get();
    },
    setRedirectToAfterConnect(url) {
      state.redirectToAfterConnect.set(url)
    }
  };
}
