import { createState, useState, postpone, none } from "@hookstate/core";
import {
	fetchListings,
	addNewListing,
	updateListing as updateDBListing,
	addNewListingOffer,
} from "src/db/firebase/listings";

const listingState = createState(fetchListings());
listingState.batch(state => {
	if (state.promised) return postpone;
});

export function useListingState() {
	const state = useState(listingState);

	return {
		get promised() {
			return state.promised;
		},
		get listings() {
			return !state.promised ? state.get() : [];
		},
		// fetch(chainID, page = 1) {
		//   console.log('chainID', chainID);
		//   const listings = fetchListings(chainID)
		//   state.set(listings);
		// },
		getListing(listingID) {
			const listing = !state.promised
				? state.get().find(item => item.listingID === listingID)
				: {};

			return listing;
		},
		getListingByTokenID(collectionID, tokenID, single = false) {
			if (state.promised) return [];

			const listings = state
				.get()
				.filter(
					item =>
						item.collectionID == collectionID &&
						item.tokenID == tokenID
				)
				.sort((a, b) => b.createdAt - a.createdAt);
			
			if (single)
				return listings.length > 0 ? listings[0] : null;

			return listings;
		},
		getActiveListingForCollection(collectionID) {
			if (state.promised) return null;

			const listings = state
				.get()
				.filter(
					item =>
						item.collectionID == collectionID && !item.purchased
				)
				.sort((a, b) => b.createdAt - a.createdAt);

			return listings.length > 0 ? listings[0] : null;
		},
		async createListing(data) {
			const listingData = {
				collection_id: data.collectionID,
				token_id: data.tokenID,
				chain_id: data.chainID,
				listing_type: data.listingType,
				auction_address: data.listingType === 'auction' ? data.auctionAddress : null,
				currency: data.currency,
				amount: data.amount,
				end_date: data.endDate ?? null,
				creator: data.creator,
			};

			if (data.listingType === "auction") {
				listingData.start_date = data.startDate;
				listingData.auction_method = data.auctionMethod;
			}

			// send to db
			const dbID = await addNewListing(listingData);

			data.listingID = dbID;

			state.merge([data]);
			console.log(state.get());

			return dbID;
		},
		getListingIDForToken(collectionID, tokenID, activeOnly = false) {
			if (state.promised) return null;

			const found = state
				.get()
				.find(
					item =>
						item.collectionID === collectionID &&
						parseInt(item.tokenID) === parseInt(tokenID) &&
						(!activeOnly || !item.purchased)
				);

			if (found) return found.listingID;

			return false;
		},
		updateListing(dbID, data) {
			const idx = state.get().findIndex(i => i.listingID === dbID);
			if (idx >= 0) {
				state[idx].merge({ ...data });
			}

			// send to db
			updateDBListing(dbID, data);
		},
		addListingOffer(dbID, data) {
			const offer = { ...data, createdAt: new Date().getTime() };
			const idx = state.get().findIndex(i => i.listingID === dbID);
			if (idx >= 0) {
				const offers = state[idx].offers?.value;
				if (!offers) {
					const obj = { offers: [offer] };
					state[idx].merge(obj);
				} else {
					state[idx].offers.merge([offer]);
				}
			}

			// send to db
			addNewListingOffer(dbID, offer);
		},
	};
}
