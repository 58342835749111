const IPFS = require("ipfs-api");
const ipfs = new IPFS({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
});

export const readFileByHash = (ipfsHash) =>
  new Promise((resolve, reject) => {
    fetch("https://ipfs.io/ipfs/" + ipfsHash)
      .then((response) => response.json())
      .then((metadata) => {
        resolve(metadata);
      })
      .catch((error) => reject(error));
  });

export default ipfs;
