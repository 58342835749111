import React, { useState, useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
	Box,
	Button,
	TextField,
	Typography,
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
	FormLabel,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import { networkName, shortAccount } from "src/helpers/blockchain.helper";
import { useProjectsState } from "src/state";

const CollectionForm = ({ onCreate, isForFrontend, ...props }) => {
	const blockchainInfo = useContext(BlockchainContext);
	const { projects } = useProjectsState();
	const [step, setStep] = useState(1);

	return (
		<Formik
			initialValues={{
				contract_type: "721",
				project_id: null,
				token_name: "",
				token_symbol: "",
				marketplace_address: "",
				royalty_percentage: 0.5,
			}}
			validationSchema={Yup.object().shape({
				contract_type: Yup.string()
					.max(255)
					.required("Contract type is required")
					.equals(["721"], "Only ERC721 is implemented at the moment"),
				project_id: process.env.REACT_APP_HAVE_PROJECTS
					? Yup.string().required("Please select a project")
					: Yup.string().nullable(true),
				token_name: Yup.string().max(100).required("Name is required"),
				token_symbol: Yup.string().max(50).required("Symbol is required"),
				royalty_percentage: Yup.number().required("Can put 0 if dont want to give any royalty"),
			})}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				onCreate &&
					onCreate({
						contractType: values.contract_type,
						projectID: values.project_id,
						name: values.token_name,
						symbol: values.token_symbol,
						owner: blockchainInfo.account,
						chainID: blockchainInfo.networkId,
						marketplaceAddress: values.marketplace_address,
						royalty: values.royalty_percentage,
					});
			}}
		>
			{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
				<form onSubmit={handleSubmit}>
					{step === 1 && (
						<Box>
							<FormControl fullWidth error={Boolean(touched.contract_type && errors.contract_type)}>
								<FormLabel sx={{ mb: 2 }} component="legend">
									Select the type of contract you would like to create
								</FormLabel>
								<Select
									fullWidth
									name="contract_type"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.contract_type}
									sx={{ mb: 2 }}
								>
									<MenuItem value="721">ERC 721</MenuItem>
									<MenuItem value="1155">ERC 1155</MenuItem>
								</Select>
								{errors.contract_type && <FormHelperText>{errors.contract_type}</FormHelperText>}
							</FormControl>
							{process.env.REACT_APP_HAVE_PROJECTS && (
								<FormControl fullWidth error={Boolean(touched.project_id && errors.project_id)}>
									<FormLabel sx={{ mb: 2 }} component="legend">
										Select the project this collection belogs to
									</FormLabel>
									<Select
										fullWidth
										name="project_id"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.project_id}
										sx={{ mb: 2 }}
									>
										{projects.map((item, index) => (
											<MenuItem key={index} value={item.id}>
												{item.project_name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
							<FormControl fullWidth>
								{!errors.contract_type &&
									(!process.env.REACT_APP_HAVE_PROJECTS ||
										(touched.project_id && !errors.project_id)) && (
										<Button variant="contained" onClick={() => setStep(2)}>
											Next
										</Button>
									)}
							</FormControl>
						</Box>
					)}
					{step === 2 && values.contract_type === "721" && (
						<Fade in={true} mountOnEnter unmountOnExit>
							<FormControl
								fullWidth
								error={Boolean(touched.contract_type && errors.contract_type)}
								component="fieldset"
							>
								<Typography mb="2" variant="h5">
									ERC {values.contract_type}
								</Typography>
								<TextField
									error={Boolean(touched.token_name && errors.token_name)}
									fullWidth
									helperText={touched.token_name && errors.token_name}
									label="Name"
									name="token_name"
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									value={values.token_name}
									variant="outlined"
									margin="normal"
								/>
								<TextField
									error={Boolean(touched.token_symbol && errors.token_symbol)}
									fullWidth
									helperText={touched.token_symbol && errors.token_symbol}
									label="Symbol"
									name="token_symbol"
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									value={values.token_symbol}
									variant="outlined"
									margin="normal"
								/>
								<TextField
									error={Boolean(touched.royalty_percentage && errors.royalty_percentage)}
									fullWidth
									helperText={
										isForFrontend
											? "Provide a Royalty Percentage you want to receive whenever these NFTs are sold on our marketplace"
											: "Provide a Royalty Percentage you want original minter shall receive whenever their NFT is sold on our marketplace"
									}
									label="Royalty Percentage"
									name="royalty_percentage"
									onBlur={handleBlur}
									onChange={handleChange}
									type="number"
									value={values.royalty_percentage}
									variant="outlined"
									margin="normal"
								/>
								{!isForFrontend && (
									<TextField
										fullWidth
										helperText="This will make the token tradeable on this marketplace without having to pay extra gas fee"
										label="Marketplace Address (optional)"
										name="marketplace_address"
										onBlur={handleBlur}
										onChange={handleChange}
										type="text"
										value={values.marketplace_address}
										variant="outlined"
										margin="normal"
									/>
								)}
								<Box sx={{ pt: 2 }}>
									<Button
										color="primary"
										disabled={
											isSubmitting ||
											!touched.token_name ||
											(errors.token_name && errors.token_name.length > 0) ||
											!touched.token_symbol ||
											(errors.token_symbol && errors.token_symbol.length > 0)
										}
										fullWidth
										size="large"
										type="submit"
										variant="contained"
									>
										Create
									</Button>
								</Box>
							</FormControl>
						</Fade>
					)}
					{step === 2 && values.contract_type === "1155" && (
						<FormControl
							fullWidth
							error={Boolean(touched.contract_type && errors.contract_type)}
							component="fieldset"
						>
							<h1>1155 </h1>
						</FormControl>
					)}
					<Typography variant="caption" component="p" sx={{ mt: 2 }} color="error" align="center">
						You are connected to {networkName(blockchainInfo ? blockchainInfo.networkId : 0)} with account{" "}
						{shortAccount(blockchainInfo ? blockchainInfo.account : null)}
					</Typography>
				</form>
			)}
		</Formik>
	);
};

export default CollectionForm;
